<template>
	<div class="bg-gray-800 text-white text-md">
		<Navigation></Navigation>
		<main class="flex h-screen px-5">
			<div class="container relative mx-auto">
				<div class="items-enter flex flex-wrap">
					<router-view/>
				</div>
			</div>
		</main>
		<Footer></Footer>
	</div>
</template>

<style>
.btn-core {
	@apply bg-white py-3 px-5 mr-2 delay-100 transition-colors border border-black border-solid border-2 text-gray-800 hover:bg-gray-300 text-xs font-bold uppercase rounded cursor-pointer;
}

.btn-action {
  @apply bg-red-500 hover:bg-red-700 py-3 px-5 mr-2 delay-100 transition-colors border-black border-solid border-2 text-gray-800 active:bg-gray-100 text-xs font-bold uppercase rounded cursor-pointer;
}
</style>
<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import CreateListModal from "@/components/modals/CreateListModal.vue";

export default {
	components: {CreateListModal, Footer, Navigation}
}



</script>