import { defineStore } from 'pinia'
import { generateUUIDUsingMathRandom } from "@/tools/guidGeneration";

export const useListDataStore = defineStore('listData', {
    state: () => {
        return {
            lists: [{
                name: 'Xmas',
                createdBy: 'Josh',
                id: '04de479b-8a49-477e-99dc-99c183bae13f',
                items: [
                    {
                        id: '0c68c80f-3cca-40bf-932c-76e516db842d',
                        name: 'Vekkia Queen Size Bed Wedge Pillow',
                        price: '$45.59',
                        url: 'https://smile.amazon.com/VEKKIA-Pillow-Headboard-Mattress-Between/dp/B08Y55RLD7/?_encoding=UTF8&pd_rd_w=fd3AD&content-id=amzn1.sym.4c241190-132a-44ce-872a-e7164e9c7eb8&pf_rd_p=4c241190-132a-44ce-872a-e7164e9c7eb8&pf_rd_r=8EGQWB253TH2GQA18WMS&pd_rd_wg=BU3eC&pd_rd_r=82488eca-74c6-4f70-841d-eaa99c0792a4&ref_=pd_gw_bmx_gp_b96ybcgo',
                        imageUrl: 'https://m.media-amazon.com/images/I/71oibzVJ4vS._AC_SL1500_.jpg',
                        personBuyingThis: 'Grandma',
                        purchased: false
                    },
                    {
                        id: '6668c80f-3cca-40bf-932c-76e516db842d',
                        name: 'Vekkia Queen Size Bed Wedge Pillow',
                        price: '$45.59',
                        url: 'https://smile.amazon.com/VEKKIA-Pillow-Headboard-Mattress-Between/dp/B08Y55RLD7/?_encoding=UTF8&pd_rd_w=fd3AD&content-id=amzn1.sym.4c241190-132a-44ce-872a-e7164e9c7eb8&pf_rd_p=4c241190-132a-44ce-872a-e7164e9c7eb8&pf_rd_r=8EGQWB253TH2GQA18WMS&pd_rd_wg=BU3eC&pd_rd_r=82488eca-74c6-4f70-841d-eaa99c0792a4&ref_=pd_gw_bmx_gp_b96ybcgo',
                        imageUrl: 'https://m.media-amazon.com/images/I/71oibzVJ4vS._AC_SL1500_.jpg',
                        personBuyingThis: 'Grandma',
                        purchased: false
                    }
                ],
                createdOn: Date.now(),
                createdOnHumanReadable: new Date().toDateString()
            }],
            currentUser : null
        }
    },
    actions : {
        createList(listName, listPassword, listCreatedBy){
            const newList = {
                name: listName,
                password: listPassword,
                createdBy: listCreatedBy,
                id: generateUUIDUsingMathRandom(),
                items: [],
                createdOn: Date.now(),
                createdOnHumanReadable: new Date().toDateString()
            };

            this.lists.push(newList);
            return newList;
        },

        deleteList(listId){
            this.lists = this.lists.filter(list => list.id !== listId);
        },

        addItem(id, itemName, itemPrice, itemUrl, itemImageUrl){
            const listToAddOnto = this.lists.find(list => list.id === id);
            const newItem = {
                id: generateUUIDUsingMathRandom(),
                name: itemName,
                price: itemPrice,
                url: itemUrl,
                imageUrl: itemImageUrl
            };

            listToAddOnto.items.push(newItem);
            return newItem;
        },

        editItem(listId, itemId, itemName, itemPrice, itemUrl, itemImageUrl){
            const listToAddOnto = this.lists.find(list => list.id === listId);
            let itemToUpdate = listToAddOnto.items.find(item => item.id === itemId);
            let indexOfItem = listToAddOnto.items.indexOf(itemToUpdate);

            listToAddOnto.items[indexOfItem] = {
                id: itemToUpdate.id,
                name: itemName,
                price: itemPrice,
                url: itemUrl,
                imageUrl: itemImageUrl
            };

            console.log(this.lists);
            return itemToUpdate;
        },

        removeItem(listId, itemId){
            const itemToRemoveFrom = this.lists.find(list => list.id === listId);
            itemToRemoveFrom.items = itemToRemoveFrom.items.filter(item => item.id !== itemId);
        },

        purchaseItem(listId, itemId) {
            const listToPurchaseFrom = this.lists.find(list => list.id === listId);
            const itemToPurchase = listToPurchaseFrom.items.find(item => item.id === itemId);
            itemToPurchase.purchased = true;
            itemToPurchase.personBuyingThis = this.currentUser.name;
        },

        unpurchaseItem(listId, itemId) {
            const listToUnpurchaseFrom = this.lists.find(list => list.id === listId);
            const itemToUnpurchase = listToUnpurchaseFrom.items.find(item => item.id === itemId);
            itemToUnpurchase.purchased = false;
            itemToUnpurchase.personBuyingThis = '';
        },

        getCurrentUser(){
            return this.currentUser;
        },

        setCurrentUser(usersName, usersBirthday){
            this.currentUser = {
                name : usersName,
                birthday : usersBirthday
            };
        }
    }
})


