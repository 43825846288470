<template>
	<Transition>
		<div class="py-12 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0" v-if=isShown
			 id="modal">
			<div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
				<div class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
					<div class="w-full flex justify-start text-gray-600 mb-3 text-2xl text-black">
						{{ title }}
					</div>

					<div class="tracking-normal leading-tight mb-4 text-gray-800">
						<slot></slot>
					</div>

					<!-- Bottom Modal Buttons -->
					<div class="flex items-center justify-start w-full">
						<button class="btn-action" @click=submitForm>Submit</button>
						<button class="btn-core" @click=toggleVisibility>Cancel</button>
					</div>

					<!-- Close button -->
					<button
						class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
						@click=toggleVisibility aria-label="close modal" role="button">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="20"
							 height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none"
							 stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z"/>
							<line x1="18" y1="6" x2="6" y2="18"/>
							<line x1="6" y1="6" x2="18" y2="18"/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script>
import {useModalDataStore} from '@/stores/modalData';

export default {
	name: "Modal",
	data() {
		return {
			submittedInputValues: [],
			store: null,
			isShown : false
		}
	},
	watch: {},
	methods: {
		toggleVisibility() {
			this.isShown = !this.isShown;
		},
		show() {
			this.isShown = true;
		},
		submitForm() {
			let formInputs = this.$el.querySelector('form').querySelectorAll('input');
			let transformedData = Array.from(formInputs).map(input => {
				return {
					'id': input.id,
					'value': input.value,
				}
			});

			this.store.submitCreateData(transformedData, this.parentName);
			this.toggleVisibility();
		}
	},
	props: {
		name: String,
		title: String,
		parentName : String
	},
	expose: ['show'],
	computed: {
	},
	mounted(){
		this.store = useModalDataStore();
	}
}
</script>

<style scoped>
.v-enter-active, .v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from, .v-leave-to {
	opacity: 0;
}
</style>