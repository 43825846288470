<template>
	<article class="block w-full">
		<h1 class="text-2xl">Welcome!</h1>
		<p class="mt-3">If you're visiting Cheerlist for the first time, please take time to create a new list.</p>
		<p class="mt-3">This website doesn't store user information, so please retain the URL of any lists you create.
			Similarly, please remember the password that you protect the list with.</p>
	</article>
	<article>
		<CreateListModal ref="create-modal"></CreateListModal>
		<div class="btn-action mt-5 ml-0" @click="this.$refs['create-modal'].show();">Create New List</div>
	</article>
</template>

<script>
import CreateListModal from '@/components/modals/CreateListModal.vue';

export default {
	name: 'HomeView',
	data() {
		return {
		}
	},
	components: {
		CreateListModal
	},
	methods: {

	},
	mounted() {
	}
}
</script>
