<template>
	<footer class="relative bg-red-400 pt-1 pb-1">
		<div class="flex flex-wrap items-center md:justify-between justify-center">
			<div class="w-full md:w-4/12 px-4 mx-auto text-center">
				<div class="text-sm text-gray-600 font-semibold py-1">
					Created by Joshua Rising in 2022.
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style scoped>

</style>