<template>
		<Modal title="New List" id="createmodal" parent-name="createListModal" ref="modal">
			<form>
				<label class="block text-gray-700 text-sm font-bold mb-2" for="my-name">My First Name</label>
				<input id="my-name" type="text" placeholder="Gary"
					   class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">

				<label class="block text-gray-700 text-sm font-bold mb-2 mt-5" for="list-name">List Name</label>
				<input id="list-name" type="text" placeholder="Xmas 2022"
					   class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">

				<label class="block text-gray-700 text-sm font-bold mb-2 mt-5" for="list-pass">Password To Edit List In
					Future</label>
				<input id="list-pass" type="password"
					   class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
			</form>
		</Modal>
</template>

<script>
import Modal from "@/components/modals/Modal.vue";
import {useModalDataStore} from "@/stores/modalData";
import {useListDataStore} from "@/stores/listData";

export default {
	name: "CreateListModal",
	components : {
		Modal
	},
	data(){
		return {
			dataForCreate: [],
			formFields: {
				name: 'list-name',
				pass: 'list-pass',
				firstName: 'my-name'
			},
			listStore: null,
			modalStore: null,
			isShown : false
		}
	},
	methods : {
		createNewList(givenFormResults) {
			const listName = givenFormResults.find(f => f.id === this.formFields.name).value;
			const listPass = givenFormResults.find(f => f.id === this.formFields.pass).value;
			const userFirstName = givenFormResults.find(f => f.id === this.formFields.firstName).value;

			const createdList = this.listStore.createList(listName, listPass, userFirstName);
			this.$router.push(`/list/edit/${createdList.id}`);
		},
		show(){
			this.$refs['modal'].show();
		}
	},
	mounted() {
		this.modalStore = useModalDataStore();
		this.modalStore.$onAction(({name, after}) => {
			after(result => {
				if (name === 'submitCreateData' && result.parentName === 'createListModal') {
					this.createNewList(result);
					this.modalStore.cleanUpCreateData(result.id);
				}
			});
		});

		this.listStore = useListDataStore();
	}
}
</script>

<style scoped>

</style>