<template>
	<nav class="w-full flex flex-wrap items-center justify-between px-2 py-3 mb-5">
		<div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
			<div class="w-full flex justify-between lg:w-auto lg:block lg:justify-start">
				<a class="text-xl font-bold inline-block mr-4 py-2 text-white"
				   :href="baseUrl" @click="goHome">
					My<span class="text-red-600">Cheerlist</span>
				</a>
				<button class="cursor-pointer text-xl px-3 py-1 rounded bg-transparent block lg:hidden outline-black"
						type="button" @click="toggleNavbar()">
					<i class="text-white fas fa-bars"></i>
				</button>
			</div>
			<div class="lg:flex flex-grow items-right" id="collapse-navbar">
				<ul class="flex flex-col lg:flex-row list-none lg:ml-auto" v-if="mobileNavbarShown">
					<nav class="flex justify-end">
						<router-link @click="navBarClicked()" to="/" class="btn-core">Home</router-link>
						<router-link @click="navBarClicked()" to="/lists" class="btn-core">My Lists</router-link>
					</nav>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: "Navigation",
	mounted() {
		if(window.screen.availWidth < 1023){
			this.isMobileModeEnabled = true;
			this.mobileNavbarShown = false;
		}
	},
	data() {
		return {
			mobileNavbarShown: true,
			isMobileModeEnabled: false
		}
	},
	methods: {
		toggleNavbar(){
			this.mobileNavbarShown = !this.mobileNavbarShown;
		},
		navBarClicked(){
			if(this.isMobileModeEnabled)
				this.toggleNavbar();
		},
		goHome(){
			this.$router.push('/');
		}
	},
	computed : {
		baseUrl(){
			return window.location.host;
		}
	}
}
</script>

<style scoped>

</style>