import { defineStore } from 'pinia';
import { generateUUIDUsingMathRandom } from "@/tools/guidGeneration";

export const useModalDataStore = defineStore('modalData', {
    state: () => {
        return {
            createData: []
        }
    },
    actions : {
        submitCreateData(formInput, parentName){
            const newInput = { ...formInput };
            newInput.id = generateUUIDUsingMathRandom();
            this.createData.push(newInput);

            formInput.id = newInput.id;
            formInput.parentName = parentName
            return formInput;
        },

        cleanUpCreateData(id){
            this.createData = this.createData.filter(c => c.id !== id);
            return this.createData;
        }
    }
})